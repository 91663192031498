<template>
  <div :id="localId">
    <div class="service_video_gallery">
      <div class="header">
        <span class="title">FEATURE Category</span>
        <span class="sub_title">The following video is "Feature".</span>
      </div>
      <div class="controller">
        <a 
          class="btn-add" title="add new video"
          @click="onAdd"
        >
          <span><i class="mdi mdi-plus"></i></span>
        </a>
        <input class="search" type="text" v-model="SearchText" />
        <div class="search_tail"></div>
        <a 
          class="btn-search" title="add new video" 
        >
          <i class="mdi mdi-magnify"></i>
        </a>
      </div>
      <div v-if="itemAvailable" class="content">
        <!-- <div class="clip_main_wrapper" >
          <div class="clip" >
            <video id="video_main" controls autoplay>
            </video>
          </div>
          <div class="comment" >
            {{ itemRepresented.comment }}
          </div>
        </div> -->
        <div v-for="(item, i) in filteredItems" class="item" :key="item.idx">
          <div 
            class="clip_item" 
            :id="`__thumb_${i}`" 
            :class="setThumb(`__thumb_${i}`, item.dataUrl)"
            @click="onSelect(item, i)"
          ></div>
          <div class="discription">
            <span class="title">{{ item.title }}</span>
            <span class="sub_title">Posted {{ postedDaysBefore(item.date) }} by {{ item.createdBy }}</span>
            <div class="comment">
              {{ getCommentShort(item.comment) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <j-overlay
      color="#000"
      opacity=".95"
      :value="view"
    >
      <div class="content_wrapper">
        <div class="prev_wrap" :class="{ disabled: selectedIndex <= 0 }" @click="onPrev"><i class="mdi mdi-chevron-left"></i></div>
        <div class="video_viewer">
          <div class="video_clip">
            <video v-if="selectedIndex >= 0" id="video_view" class="video" controls autoplay controlsList="nodownload">
              <!-- <source :src="`${__C_.HOST_NAME}${selectedItem.path}`"> -->
            </video>
          </div>
          <div class="item_comment">
            {{ selectedItem.comment }}
          </div>
        </div>
        <div class="next_wrap" :class="{ disabled: selectedIndex >= items.length-1 }" @click="onNext"><i class="mdi mdi-chevron-right"></i></div>
      </div>
    </j-overlay>
    <div v-if="view" class="overlay_footer">
      <div v-html="slideFilenames" class="file_names"></div>
      <div class="action">
        <a class="download" @click="onDownload">
          <i class="mdi mdi-download"></i> <span>DOWNLOAD</span>
        </a>
        <div class="action_spacer"></div>
        <a class="edit" @click="onEdit">
          <i class="mdi mdi-pencil"></i> <span>EDIT</span>
        </a>
        <div class="action_spacer"></div>
        <a class="edit" @click="onDelete">
          <i class="mdi mdi-trash-can-outline"></i> <span>DELETE</span>
        </a>
      </div>
    </div>

    <j-modal-slide v-model="maOpened" :width="750">
      <template #header>
        <div class="header">
          <span class="title">Video Gallery</span>
          <small class="sub_title">Upload</small>
        </div>
      </template>
      <div class="content">
        <div class="content_header">
          <span class="content_header_title">Upload / Modification</span>
        </div>
        <v-form v-model="valid" ref="form">
          <v-layout wrap>
            <!-- <v-flex sm3 class="wrap__select">
              <v-select
                v-model="selectedItem.module"
                dense
                required
                class="field"
                label="Modlue / Area"
                item-text="text"
                item-value="value"
                placeholder="Select a Module"
                :menu-props="{contentClass:'single_select'}"
                :items="moduleOptions"
              ></v-select>
            </v-flex>
            <v-flex sm3 class="wrap__select">
              <div class="form_spacer"></div>
              <v-select
                v-model="selectedItem.block"
                dense
                required
                class="field"
                label="Block No."
                item-text="text"
                item-value="value"
                placeholder="Select a Block No."
                :menu-props="{contentClass:'single_select'}"
                :items="blockOptions"
              ></v-select>
            </v-flex>
            <v-flex sm3 class="wrap__select">
              <div class="form_spacer"></div>
              <v-select
                v-model="selectedItem.category"
                dense
                required
                class="field"
                label="Category"
                item-text="text"
                item-value="value"
                placeholder="Select a Category"
                :menu-props="{contentClass:'single_select'}"
                :items="descOptions"
              ></v-select>
            </v-flex> -->
            <v-flex sm3 class="wrap__select">
              <div class="form_spacer"></div>
              <j-date-picker
                v-model="updatedDate"
                class="modal"
                :nudge-top="-27"
                :nudge-left="148"
                :offset-y="true"
                :menu-class="'bottom'"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex sm5>
              <v-text-field
                v-model="selectedItem.title"
                class="__title"
                label="Title"
                placeholder="Input/Edit Icon Title"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 wrap__textarea>
              <v-textarea
                v-model="selectedItem.comment"
                outline
                label="Comment"
              ></v-textarea>
            </v-flex>
          </v-layout>

          <div class="uploader_wrapper">
            <j-fileuploader 
              ref="fileUploader"
              accepted="video/*"
              :selectedItem="selectedItem"
              @complete="onComplete"
              @thumbnail="onThumbnail"
            />
            <div class="form_spacer"></div>
            <div class="botton_wrapper">
              <j-button v-if="modeNew" class="type01 sky  type_full" :class="{ disabled: disabled }" :disabled="disabled" @click="onUpload">Upload</j-button>
              <j-button v-else class="type01 sky  type_full" :class="{ disabled: disabled }" :disabled="disabled" @click="onUpload">Update</j-button>
              <div class="form_spacer"></div>
              <j-button v-if="modeEdit" class="type01 delete  type_full" @click="onDelete">Delete</j-button>
            </div>
          </div>

        </v-form>
      </div>
    </j-modal-slide>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_service.video.gallery.styl'

import * as d3 from 'd3'
import { mapState, mapMutations } from 'vuex'
// import FileDownloader from 'js-file-download'
import Moment from 'moment'

import __C from '@/primitives/_constant_'
import __data from '@/primitives/_temp_gallery_options'
import Url from '@/services/api/request.url'

import { FileService, FetchStreamService} from "@/services"
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Loading from '@/mixins/loading.mixin'

export default {
  name: "service-video-gallery",
  mixins: [
    Loading,
    SafeIdMixin
  ],
  data: () => ({
    FetchStreamService: null,
    fileService: null,
    items: [],
    maOpened: false,
    mode: __C.FORM.EDIT_MODE_NEW,
    
    selectedIndex: 0,
    selectedItem: {},

    fullSizeInfo: {
      src: '',
      width: 0,
      height: 0,
    },
    full: false,
    view: false,
    valid: false,

    defaultRules: [v => !!v || "Required"],

    // temporal
    blockOptions: __data.block,
    moduleOptions: __data.module,
    descOptions: __data.discDesc,

    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},
    SearchText: ''
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    __C_() { return __C },

    updatedDate: {
      get() { return this.selectedItem.date || Moment().format('YYYY-MM-DD') },
      set(val) { this.selectedItem = { ...this.selectedItem, date: val } } 
    },

    itemAvailable() { return this.items.length > 0 },
    itemRepresented() { 
      this.items[0].comment = this.items[0].comment == 'undefined' ? '' : this.items[0].comment
      return this.items[0]
       },

    disabled() { return !this.updatable },
    updatable() {
      if(!this.selectedItem.title) return false
      if(this.modeNew) {
        if(!this.selectedItem.files || this.selectedItem.files.length === 0) return false
      } else if(this.selectedIndex < 0) return false
      return true
    },

    slideFilenames() {
      if(this.selectedIndex < 0) return ''

      let prevName = this.selectedIndex > 0 ? this.items[this.selectedIndex - 1].title : ''
      let nextName = this.selectedIndex < this.items.length - 1 ? this.items[this.selectedIndex + 1].title : ''
      return  (prevName ? `<span class="file_name_prev"><span>${prevName}</span> <span class="icon"><</span></span>` : '') +
              `<span class="file_name_current">${this.items[this.selectedIndex].title}</span>` +
              (nextName ? `<span class="file_name_next"><span class="icon">></span> <span class="text">${nextName}</span></span>` : '')
    },
    viewValid() { return this.selectedIndex >= 0 && Object.keys(this.selectedItem).length > 0 },
    modeNew() { return this.mode == __C.FORM.EDIT_MODE_NEW },
    modeEdit() { return this.mode == __C.FORM.EDIT_MODE_EDIT },

    filteredItems() {
      if(this.SearchText) return this.items.filter(item => item.title.toLowerCase().includes(this.SearchText.toLowerCase()))
      return this.items
    },
  },
  beforeCreate() {
    this.loading = true
  },
  created() {
    this.localId = this.id || 'j-modal-slide__' + this.safeId('')
    this.setChild('')
    this.fileService = new FileService()
    this.fetchStreamService = new FetchStreamService()
  },
  mounted() {
    this.loading = false
    this.init()
    this.getFiles(__C.FILE.GALLERY_TYPE_VIDEO)
    // this.videoMainFiles()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [ 'setChild' ]),

    init() {
      d3.select(`#${this.localId}`).select('.j-overlay__scrim').on('click', this.onOverlayClick)
    },

    onPrev() {
      if(this.selectedIndex <= 0) return

      this.setFadeout().then(() => {
        this.selectedIndex -= 1
        this.selectedItem = this.items[this.selectedIndex]
        this.setVideoSource()
        this.setViewDimention()
      })
    },
    onNext() {
      if(this.selectedIndex >= this.items.length-1) return

      this.setFadeout().then(() => {
        this.selectedIndex += 1
        this.selectedItem = this.items[this.selectedIndex]
        this.setVideoSource()
        this.setViewDimention()
      })
    },

    onAdd() {
      // document.getElementById('video_main').pause()
      this.selectedItem = {}
      this.selectedItem.date = Moment().format('YYYY-MM-DD')
      this.mode = __C.FORM.EDIT_MODE_NEW
      this.maOpened = true
    },
    onCloseFullSize() {
      this.toggleFullscreen()
      this.full = false
    },
    onDownload() {
      document.getElementById('video_view').pause()
      var iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      let params = new URLSearchParams()
      params.append('token', this.account.token)
      iframe.src = `${Url.download}/${this.selectedItem.fileId}?${params.toString()}`

      // this.fileService.download(this.selectedItem.fileId).then(res => {
      //   FileDownloader(res, this.selectedItem.name)
      //   // this.loading = false
      //   // this.view = false
      //   // this.close()
      //   // this.getFiles(__C.FILE.GALLERY_TYPE_VIDEO)
      // })
    },
    onEdit() {
      document.getElementById('video_view').pause()
      this.mode = __C.FORM.EDIT_MODE_EDIT
      this.maOpened = true
    },

    onComplete(files) {
      this.selectedItem.files = files
      this.selectedItem.ext = files[0].ext
      this.selectedItem = { ...this.selectedItem }
    },
    onDelete() {
      // document.getElementById('video_view').pause()

      if ( ['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit) || (this.selectedItem.createdBy == this.account.userName) ) {
        this.yes = () => {
          this.msgOpen = false
          this.fileService.delFileFromGallery(this.selectedItem.idx, this.selectedItem.fileId).then(() => {
            this.getFiles(__C.FILE.GALLERY_TYPE_VIDEO)
            // this.videoMainFiles()
            this.loading = false
            this.view = false
            this.close()
          })
          this.yes = () => {}
        }

        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Action Approval"
        this.msgInfo.titleDescription = "Important Notification"
        this.msgInfo.message = "Do you want to delete selected Item?"
        this.msgInfo.button = [true, false, true]
        this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
        this.msgInfo.buttonText[0] = "Delete"
        this.msgOpen = true

      } else if ( this.selectedItem.createdBy != this.account.userName ) {
        this.loading = false
        this.yes = () => {
          this.msgOpen = false
          this.yes = () => {}
        }

        this.msgInfo.type = "WARN"
        this.msgInfo.title = "You do not have permission to delete."
        this.msgInfo.titleDescription = "Important Notification"
        this.msgInfo.button = [false, false, true]
        this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
        this.msgOpen = true
      }
    },
    onOverlayClick() {
      this.view = false
    },
    onSelect(item, i) {
      this.selectedIndex = i
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.setVideoSource()
      this.view = true
      // document.getElementById('video_main').pause()
      setTimeout(() => { this.setViewDimention() }, 150)
    },
    onShowFullSize() {
      this.toggleFullscreen()

      let dimention = this.selectedItem.dimention.split(',')
      this.fullSizeInfo = {
        src: `${__C.HOST_NAME}${this.selectedItem.path}`,
        width: dimention[0] || 0,
        height: dimention[1] || 0,
      }
      this.full = true
    },
    onThumbnail(data) {
      this.selectedItem.dataUrl = data.dataUrl
    },
    onUpload() {
      this.loading = true

      let params = new FormData()
      params.set('idx', this.modeNew ? 0 : this.selectedItem.idx)
      params.set('fileId', this.modeNew ? 0 : this.selectedItem.fileId)

      if(this.selectedItem.files) {
        this.selectedItem.files.forEach(f => { params.append("videos", f) })
        params.set('name', this.selectedItem.files[0].name)
        params.set('size', this.selectedItem.files[0].size)

        let dimention = (
          this.selectedItem.files[0].width ?
          `${this.selectedItem.files[0].width},${this.selectedItem.files[0].height}` :
          ''
        )
        params.set('dimention', dimention)
        params.set('ext', this.selectedItem.ext)
        params.set('dataUrl', this.selectedItem.dataUrl)
        params.set('added', 'Y')
      }

      // TODO: On multi file upload...

      // let img = document.querySelector(".dz-image img")
      // this.selectedItem.width = parseInt(img.naturalWidth)
      // this.selectedItem.height = parseInt(img.naturalHeight)

      params.set('type', __C.FILE.GALLERY_TYPE_VIDEO)
      params.set('title', this.selectedItem.title)
      params.set('module', this.selectedItem.module)
      params.set('block', this.selectedItem.block)
      params.set('category', this.selectedItem.category)
      params.set('comment', this.selectedItem.comment)
      params.set('date', this.selectedItem.date)
      params.set('createdBy', this.account.userName)
      params.set('description', '')

      if(this.modeNew) this.fileService.putFile2Gallery(params).then(res => {
        this.getFiles(__C.FILE.GALLERY_TYPE_VIDEO)
        // this.videoMainFiles()
        this.loading = false
        this.close()

      }); else if (['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit) || (this.selectedItem.createdBy == this.account.userName) ) this.fileService.updFile2Gallery(params).then(res => {
        this.loading = false
        this.close()
        this.view = false
        
        if(res) {
          let i = this.items.findIndex(item => item.idx === res.idx)
          if(i >= 0) this.items[i] = res
          this.selectedItem = res
        }
      })
      else if ( this.selectedItem.createdBy != this.account.userName ) {
        this.loading = false
        this.yes = () => {
        this.msgOpen = false
        this.yes = () => {}
      }
        this.msgInfo.type = "WARN"
        this.msgInfo.title = "You do not have permission to change."
        this.msgInfo.titleDescription = "Important Notification"
        this.msgInfo.button = [false, false, true]
        this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
        this.msgOpen = true
      }
    },

    close() {
      this.mode = __C.FORM.EDIT_MODE_NEW
      this.maOpened = false
      this.selectedIndex = -1
      this.selectedItem = {}
      if(this.$refs.fileUploader) this.$refs.fileUploader.init()
    },

    getCommentShort(comment) {
      let limited_ = 180
      return comment.length > limited_ ? `${comment.substr(0, limited_)}...` : comment
    },
    getFiles(type) {
      this.loading = true

      let params = new FormData()
      
      params.set('type', type)

      this.fileService.getFilesFromGallery(params).then(res => {
        if(!res) this.items = []
        else this.items = res

        this.loading = false
      })
    },
     videoMainFiles() {
      this.loading = true
      setTimeout(() => {
        let data = {
          token : this.account.token,
          path: this.itemRepresented.path
        }
        this.streamVideo(data, "video_main", "video", "select")     
      }, 1000);

    this.loading = false

    },
    streamVideo(data, target, c1, c2) {
        this.fetchStreamService.selectVideoFile(data)
        .then(response => response.blob(), this.loading = true)
        .then(blob => {

          const blobURL = URL.createObjectURL(blob)
          const chunks = []
          const mimeCodec = "vdeo/webm; codecs=opus"
          let duration
          let media = document.getElementById(`${target}`)
          this.loading=false
          media.onloadedmetadata = () => {
            media.onloadedmetadata = null
            duration = Math.ceil(media.duration)
            let arr = Array.from({
              length: duration
            }, (_, index) => index)
            // record each second of media
            arr.reduce((p, index) =>
                p.then(() =>
                  new Promise(resolve => {
                    let recorder
                    let video = document.createElement(`${c1}`)
                    video.onpause = e => {
                      video.onpause = null
                      recorder.stop()
                    }

                    video.oncanplay = () => {
                      video.oncanplay = null

                      let stream = video.captureStream()

                      recorder = new MediaRecorder(stream)

                      recorder.start()

                      recorder.ondataavailable = e => {
                        chunks.push(e.data)
                      }

                      recorder.onstop = e => {
                        resolve()
                      }
                    }
                    video.src = `${blobURL}#t=${index},${index+1}`
                  })
                ), Promise.resolve())
              .then(() => {
                let video = document.createElement(`${c1}`)
                video.controls = true
                let video_main = document.getElementsByClassName('clip')
                video_main.appendChild(video)
                let select = document.createElement(`${c2}`)
                video_main.appendChild(select)
                let option = new Option("select a segment")
                select.appendChild(option)
                for (let chunk of chunks) {
                  let index = chunks.indexOf(chunk)
                  let option = new Option(`Play ${index}-${index + 1} seconds of media`, index)
                  select.appendChild(option)
                }
                let fullMedia = new Blob(chunks, {
                  type: mimeCodec
                })

                let opt = new Option("Play full media", "Play full media")
                select.appendChild(opt)
                select.onchange = () => {
                  if (select.value !== "Play full media") {
                    video.src = URL.createObjectURL(chunks[select.value])
                  } else {

                    const mediaSource = new MediaSource()
                    video.src = URL.createObjectURL(mediaSource)
                    mediaSource.addEventListener("sourceopen", function() {
                      // if the media type is supported by `mediaSource`
                      // fetch resource, begin stream read, 
                      // append stream to `sourceBuffer`
                      if (MediaSource.isTypeSupported(mimeCodec)) {
                        var sourceBuffer = mediaSource.addSourceBuffer(mimeCodec)
                        // set `sourceBuffer` `.mode` to `"sequence"`
                        sourceBuffer.mode = "segments"

                        fetch(URL.createObjectURL(fullMedia))
                          // return `ReadableStream` of `response`
                          .then(response => response.body.getReader())
                          .then(reader => {
                            const processStream = (data) => {
                                if (data.done) {
                                  return
                                }
                                // append chunk of stream to `sourceBuffer`
                                sourceBuffer.appendBuffer(data.value)
                              }
                              // at `sourceBuffer` `updateend` call `reader.read()`,
                              // to read next chunk of stream, append chunk to 
                              // `sourceBuffer`
                            sourceBuffer.addEventListener("updateend", function() {
                              reader.read().then(processStream)
                            })
                            // start processing stream
                            reader.read().then(processStream)
                            // do stuff `reader` is closed, 
                            // read of stream is complete
                            return reader.closed.then(() => {
                              // signal end of stream to `mediaSource`
                              mediaSource.endOfStream()
                              return mediaSource.readyState
                            })
                          })
                      }
                      // if `mimeCodec` is not supported by `MediaSource`  
                      else {
                        alert(mimeCodec + " not supported")
                      }
                    })
                  }
                }
              })
          }
          media.src = blobURL
        }) 
    },
    postedDaysBefore(date) {
      if(!date) return ''
      let days = Moment().diff(Moment(date), 'days')
      return `${days} day${days > 1 ? 's' : ' '} ago`
    },
    setFadeout() {
      return new Promise(resolve => {
        d3.select(`#${this.localId}`).select('.video').transition().duration(500).style('opacity', 0)
        setTimeout(() => { resolve(true) }, 500)
      })
    },
    setVideoSource() {
      this.loading = true
      setTimeout(() => {
        // document
        // .querySelector(`#${this.localId} .j-overlay__content .video`)
        // .setAttribute('src', `${__C.HOST_NAME}${this.selectedItem.path}`)
        let data = {
          token : this.account.token,
          path: this.selectedItem.path
        }
        this.streamVideo(data, "video_view", "video_select", "select_item")
      },1000)
      this.loading = false
    },
    setViewDimention() {
      let videoViewHeight = 870
      let dimention = this.selectedItem.dimention.split(',')

      if(!dimention || dimention.length === 0) return 
      
      let w_ = Number(dimention[0])
      let h_ = Number(dimention[1])
      let inW_ = 0
      let inH_ = 0

      if(h_ <= videoViewHeight) {
        inW_ = w_
        inH_ = h_
      } else {
        let ratio_ = videoViewHeight / h_
        inW_ = Math.round(w_ * ratio_)
        inH_ = videoViewHeight
      }
      d3.select(`#${this.localId}`)
      .select('.video_viewer')
      .transition()
      .duration(750)
      .style('width', `${inW_}px`)
      // .style('height', `${inH_}px`)

      d3.select(`#${this.localId}`).select('.video').transition().duration(500).style('opacity', 1)
    },
    setThumb(id, dataUrl) {
      setTimeout(() => {
        document.querySelector(`#${id}`).style.backgroundImage = 'url("' + dataUrl + '")'
      })
      return ''
    },
    toggleFullscreen(el) {
      el = el || document.documentElement
      if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen()
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen()
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen()
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }
    }
  }
}
</script>
